<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card pt-4" style="padding-left: 5%; padding-right: 5%">
        <div class="row">
          <h2 class="col-lg-11">{{ sectionVideo.titre }}</h2>
          <div class="col-lg-1">
            <p class="card-title-desc">
              <b-dropdown
                class="card-drop"
                variant="white"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
              >
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>
                <!-- <b-dropdown-item :href="'/projects/overview/' + section.id"
                                        >{{ $t("pages.sections.actions.details") }}</b-dropdown-item
                                    > -->
                <b-dropdown-item @click="showUpdateModal">{{
                  $t("pages.sections.actions.modifier")
                }}</b-dropdown-item>
                <b-dropdown-item @click="confirm(sectionVideo.id)">{{
                  $t("pages.sections.actions.supprimer")
                }}</b-dropdown-item>
              </b-dropdown>
            </p>
          </div>
        </div>

        <div class="swiper-container">
          <div class="swiper-wrapper display" v-if="categories.length > 0">
            <VideosCard
              v-for="video in sectionVideo.videos.slice().reverse()"
              :key="video.id"
              :affichage="'Grille'"
              :video-props="video"
              :chaines="channels"
              :categ="categories"
              :tag="tagList"
              :programme="programmes"
            ></VideosCard>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-xl"
    size="xl"
    :title="$t('pages.sections.formulaire.updateTitle')"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-12">
            <label class="col-form-label">{{
              $t("pages.sections.formulaire.nom.label")
            }}</label>
            <div class="col-md-11">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="titreSection"
                :placeholder="$t('pages.sections.formulaire.nom.placeholder')"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="col-lg-1">
        <br /><br />
        <button @click="updateSection" class="btn c2play-primary">
          {{ $t("updateButton") }}
        </button>
      </div>

      <br />
      <div class="row">
        <div
          class="col-lg-3"
          v-for="video in sectionUpdate.slice().reverse()"
          :key="video.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="video.id"
          >
            <div>
              {{ video.title }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <br />
      <div style="width: 100%; border: 1px solid #007bff"></div>
      <br />

      <div class="row">
        <div
          class="col-lg-3"
          v-for="video in allVideo.slice().reverse()"
          :key="video.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="video.id"
          >
            <div>
              {{ video.title }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import VideosCard from "../VideosCard.vue";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";

export default {
  components: {
    VideosCard,
  },
  data() {
    return {
      updateModal: false,
      categories: [],
      channels: [],
      sectionVideo: {
        id: "",
        titre: "",
        videos: [],
      },
      updateVideos: [],
      sectionUpdate: [],
      idVideosUpdateSection: [],
      titreSection: "test",
    };
  },
  props: {
    id: {},
    titre: {},
    videos: [],
    allVideo: [],
  },
  mounted() {
    this.getChannel();

    Api.get("/streamvod/rest/categories/all")
      .then((res) => {
        this.categories = res.data.content;
        console.log(res);
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    this.sectionVideo.id = this.id;
    this.sectionVideo.titre = this.titre;
    this.sectionVideo.videos = this.videos;

    //data for update section

    this.updateVideos = this.allVideo;
    this.sectionUpdate = this.videos;
    this.titreSection = this.titre;
    this.sectionUpdate.forEach((items) => {
      this.idVideosUpdateSection.push(items.id);
    });
  },
  methods: {
    showUpdateModal() {
      this.updateModal = true;
    },
    getChannel() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      // Call the Api.get function to get the channel data
      Api.get("/streamvod/api/channel/channel-all", onUploadProgress)
        .then((response) => {
          // If the channel data is received successfully
          this.channels = response.data.content; // Store the channel data in the channels variable
        })
        .catch((error) => {
          // If there's an error
          this.process = false; // Set the process variable to false
          Erreur.gestionErreur(error.message); // Call the error handling function
        });
    },
    confirm(sectionId) {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteProgramme(sectionId);
          Swal.fire("Deleted!", "Event has been deleted.", "success");
          location.reload();
        }
      });
    },
    deleteProgramme(sectionId) {
      Api.delete("/streamvod/rest/section/delete/" + sectionId)
        .then((response) => {
          this.channels = response.data.content;
          console.log(this.channels);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    updateSection() {
      this.process = true;
      this.modalOk = false;
      console.log(this.idVideosUpdateSection);
      Api.putFormData(
        `/streamvod/rest/section/update/${this.sectionVideo.id}?videos_id=${this.idVideosUpdateSection}`,
        {
          titre: this.titreSection,
        }
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Section Modifiée", "success");
          location.reload();
          console.log(response);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
