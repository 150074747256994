<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
var moment = require("moment");
moment.locale("fr");
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import sectionVideoBlock from "../../../components/widgets/card/sectionVideoBlock.vue";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, sectionVideoBlock },
  data() {
    return {
      secondModalOk:false,
      sectionsProgramme: [],
      NoUseSections:[],
      idSection: [],
      modalOk: false,
      moment: moment,
      programme: [],
      sections: [],
      videos: [],
      title: "Details Programme",
      items: [
        {
          text: "Programmes",
          href: "/projects/programmes",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },
    showSecondModal() {
      this.secondModalOk = true;
    },

    addSectionProgramme() {

      Api.put(
        `/streamvod/rest/programme/add-section/${this.$route.params.id}?section_id=${this.idSection}`
      )
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getSection() {
      Api.get("/streamvod/rest/section/all")
        .then((response) => {
          this.sections = response.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    getSectionNoUse() {
      Api.get("/streamvod/rest/section/all-test")
        .then((response) => {
          this.NoUseSections = response.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  async mounted() {
    this.id = this.$route.params.id;

    const result = await Api.get(
      "/streamvod/rest/programme/" + this.$route.params.id
    );
    this.programme = result.data.content;
    this.image = this.programme.imageportrait.url;
    this.sectionsProgramme = this.programme.sections;
    this.sectionsProgramme.forEach((item) => {
      this.idSection.push(item.id)
    })
    this.$refs.videoPlayer.src = this.programme.videocouverture.url;

    this.getSection();
    this.getSectionNoUse();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button type="submit" class="btn c2play-primary" style="position: relative; left: 84%" @click="showModal">
          Associer une section
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button type="submit" class="btn c2play-primary" style="position: relative; left: 84%" @click="showSecondModal">
          Retirer une section
        </button>
      </div>
    </div>
    <br />
    <div class="card" style="padding: 30px; border-radius: 7px">
      <div class="row">
        <div class="col-xl-7">
          <div class="product-detai-imgs" style="">
            <div style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              " itemscope itemtype="https://schema.org/VideoObject">
              <video ref="videoPlayer" src="" controls autoplay style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                " width="720" height="480"></video>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="mt-3">
            <div style="text-align: justify">
              <h2>{{ programme.nom }}</h2>
              <br />
              <h5>
                <strong>{{
                  $t("pages.overviewCateg.dataCateg.description")
                }}</strong>
                : {{ programme.description }}
              </h5>
              <h5><strong>Durée</strong> : {{ programme.duree }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br /><br />


    <div v-for="section in sectionsProgramme" :key="section.id">
      <sectionVideoBlock :id="section.id" :titre="section.titre" :videos="section.videos" :all-video="videos">
      </sectionVideoBlock>
    </div>

    <b-modal v-model="secondModalOk" id="modal-xl" size="xl" :title="$t('pages.sections.formulaire.title')"
      title-class="font-18" hide-footer>
      <div class="card-body">
        <div class="col-lg-1">
          <button @click="addSectionProgramme" class="btn c2play-primary">
            {{ $t("addButton") }}
          </button>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-3" v-for="section in sections.slice().reverse()" :key="section.id">
            <b-form-checkbox v-model="idSection" name="checkbox-1" :value="section.id">
              <div>
                {{ section.titre }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="modalOk" id="modal-xl" size="xl" :title="$t('pages.sections.formulaire.title')"
      title-class="font-18" hide-footer>
      <div class="card-body">
        <div class="col-lg-1">
          <button @click="addSectionProgramme" class="btn c2play-primary">
            {{ $t("addButton") }}
          </button>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-3" v-for="section in NoUseSections.slice().reverse()" :key="section.id">
            <b-form-checkbox v-model="idSection" name="checkbox-1" :value="section.id">
              <div>
                {{ section.titre }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>